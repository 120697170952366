@font-face {
  font-family: 'Helvetica neue';
  src: url('/src/assets/fonts/helveticaneue.eot'); /* IE9 Compat Modes */
  src: url('/src/assets/fonts/helveticaneue.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/helveticaneue.woff') format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/helveticaneue.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/src/assets/fonts/helveticaneue.svg#HelveticaNeue') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

:root {
  --yit-blue: #009fda;
  --yit-blue-80: rgba(0, 159, 218, 0.8);
  --yit-grey: #294754;
  --yit-grey-40: rgba(41, 71, 84, 0.4);
  --yit-red: #db4d69;
  --yit-orange: #e98300;
  --yit-green: #3f9c35;
  --yit-white: #ffffff;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
